import { useMutation, useQuery } from "@tanstack/react-query";
import mandateService from "../../services/mandate.service";
import { useNavigate, useParams } from "react-router-dom";
import { TBody, Table, Td, Tr } from "../../layout/table";
import { timeAgo } from "../../sections/users/cards/calls-list";
import mandateEffectService from "../../services/mandate-effect.service";
import { useMandateMandateEffects } from "../../mandate-mandate-effects/hooks/use-mandate-mandate-effects";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useMemo, useState } from "react";
import Swal from "sweetalert2";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import ButtonNeoGen from "../../layout/button-neogen";
import { orderBy, startCase } from "lodash";
import { deleteMandateMandateEffect } from "../../mandate-mandate-effects/actions/delete-mandate-mandate-effect";
import { MandateMandateEffect } from "../domain/mandate-mandate-effect";
import { AddMandateEffectModal } from "../../mandate-mandate-effects/components/add-mandate-effect-modal";
import { MandateContentsModal } from "../components/mandate-contents-modal";
import { getMandate } from "../actions/get-mandate";
import { format } from "date-fns";

function MandatePage({ darkMode }: { darkMode?: boolean }) {
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const navigate = useNavigate();
    const { mandateId: m } = useParams();
    const mandateId = parseInt(m || "", 10);
    const [showAddEffectModal, setShowAddEffectModal] = useState(false);
    const [showContents, setShowContents] = useState(false);

    const mandatesQuery = useQuery(["mandate"], async () => {
        if (!mandateId) return;
        const mandate = await getMandate({ authToken, id: mandateId });
        return mandate;
    });
    const mandate = mandatesQuery.data;

    const mandatesEffectQuery = useQuery(["mandateEffect"], async () => {
        const result = await mandateEffectService.getAll();
        return result?.data;
    });

    const mandateMandateEffectQuery = useMandateMandateEffects(
        {
            authToken,
            filters: { ...(mandateId !== null ? { where: { mandateId: mandateId } } : {}) },
        },
        { enabled: !!mandateId },
    );
    const mandateMandateEffects = useMemo(() => mandateMandateEffectQuery.data || [], [mandateMandateEffectQuery.data]);

    const deleteMandateMandateEffectMutation = useMutation({
        mutationFn: (id: number) => deleteMandateMandateEffect({ authToken, id }),
        onSuccess: () => {
            mandateMandateEffectQuery.refetch();
        },
    });

    return (
        <>
            {showAddEffectModal && mandateId && (
                <AddMandateEffectModal
                    mandateId={mandateId}
                    onClose={() => setShowAddEffectModal(false)}
                    onCreated={() => {
                        mandateMandateEffectQuery.refetch();
                        setShowAddEffectModal(false);
                    }}
                />
            )}
            {showContents && mandate && (
                <MandateContentsModal mandate={mandate} onClose={() => setShowContents(false)} />
            )}
            {mandatesQuery.data ? (
                <div className="flex flex-col">
                    <div style={{ width: "50vw", margin: "auto" }}>
                        <div className="flex justify-between align-middle p-3">
                            <div className="flex items-center cursor-pointer" onClick={() => navigate("/mandates")}>
                                <ChevronLeftIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                Mandates / {mandate?.name}
                            </div>
                            <div className="flex gap-2">
                                <ButtonNeoGen onClick={() => setShowContents(true)}>View Content</ButtonNeoGen>
                                <ButtonNeoGen>Trigger AI</ButtonNeoGen>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                                <Table>
                                    <TBody>
                                        <Tr>
                                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>
                                                Mandate Name
                                            </Td>
                                            <Td>{mandate?.name}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>Date</Td>
                                            <Td>{mandate?.date && format(mandate.date, "d MMMM yyyy")}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>City</Td>
                                            <Td>{mandate?.city}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>State</Td>
                                            <Td>{mandate?.state}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>County</Td>
                                            <Td>{mandate?.county}</Td>
                                        </Tr>
                                    </TBody>
                                </Table>
                            </div>
                            <div>
                                <Table>
                                    <TBody>
                                        <Tr>
                                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>
                                                AI Status
                                            </Td>
                                            <Td>{startCase(mandate?.status || "-")}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>
                                                Processed At
                                            </Td>
                                            <Td>
                                                {mandate?.processedByAiAt
                                                    ? timeAgo(mandate?.processedByAiAt).toLocaleString()
                                                    : "-"}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td style={{ width: 200, verticalAlign: "top", color: "gray" }}>
                                                AI Version
                                            </Td>
                                            <Td>{mandate?.aiVersion || "-"}</Td>
                                        </Tr>
                                    </TBody>
                                </Table>
                            </div>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <div style={{ padding: 12 }}>
                                <b>Mandate Summary</b>
                            </div>
                            <div style={{ backgroundColor: "white", padding: 24 }}>
                                <div>{mandate?.summary}</div>
                            </div>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: 12,
                                }}
                            >
                                <div>
                                    <b>Mandate Effects</b>
                                </div>
                                <div>
                                    <ButtonNeoGen onClick={() => setShowAddEffectModal(true)}>Add Effect</ButtonNeoGen>
                                </div>
                            </div>
                            <Table>
                                <TBody>
                                    {mandateMandateEffects.length === 0 && (
                                        <Tr>
                                            <Td colSpan={3} style={{ textAlign: "center" }}>
                                                No Mandate Effects
                                            </Td>
                                        </Tr>
                                    )}
                                    {mandateMandateEffects.length > 0 &&
                                        orderBy(
                                            mandateMandateEffects.map((mf) => {
                                                const mandateEffect = mandatesEffectQuery.data?.find(
                                                    (m) => m.id === mf.mandateEffectId,
                                                );
                                                return {
                                                    mandateMandateEffect: mf,
                                                    mandateEffect,
                                                };
                                            }),
                                            ({ mandateEffect }) => mandateEffect?.effectName,
                                        ).map(({ mandateEffect, mandateMandateEffect }) => {
                                            return (
                                                <Tr key={mandateMandateEffect.id}>
                                                    <Td style={{ width: 200, verticalAlign: "top" }}>
                                                        <b>{startCase(mandateEffect?.effectName || "")}</b>
                                                    </Td>
                                                    <Td>{mandateMandateEffect.description}</Td>
                                                    <Td style={{ width: 120 }}>
                                                        <ButtonNeoGen
                                                            type="outline-danger"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: "Are you sure?",
                                                                    text: "You won't be able to revert this!",
                                                                    icon: "warning",
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: "#3085d6",
                                                                    cancelButtonColor: "#d33",
                                                                }).then((result) => {
                                                                    if (result.value) {
                                                                        deleteMandateMandateEffectMutation.mutate(
                                                                            mandateMandateEffect.id,
                                                                        );
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            Remove
                                                        </ButtonNeoGen>
                                                    </Td>
                                                </Tr>
                                            );
                                        })}
                                </TBody>
                            </Table>
                        </div>
                    </div>
                </div>
            ) : (
                <p>No Mandate Found</p>
            )}
        </>
    );
}

export default MandatePage;
