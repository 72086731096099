import { useMutation } from "@tanstack/react-query";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { useAuth } from "../../auth/use-auth";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useMemo, useRef, useState } from "react";
import { orderBy, startCase } from "lodash";
import { format } from "date-fns";
import ButtonNeoGen from "../../layout/button-neogen";
import { Select } from "../../layout/form/select-input";
import { AutoAssignMandatesModal } from "../../company-mandate-assignments/components/auto-assign-mandates-modal";
import {
    UpdateCompanyMandateAssignment,
    updateCompanyMandateAssignment,
} from "../../company-mandate-assignments/actions/update-company-mandate-assignment";
import { Link } from "react-router-dom";
import { AddMandateAssignmentModal } from "../../company-mandate-assignments/components/add-mandate-assignment-modal";
import { useMandates } from "../../mandates/hooks/use-mandates";
import { useMandateMandateEffects } from "../../mandate-mandate-effects/hooks/use-mandate-mandate-effects";
import { useIndustryGroups } from "../../industry-groups/hooks/use-industry-groups";
import { useMandateEffects } from "../../mandate-effects/hooks/use-mandate-effects";
import { useCompanyMandateAssignments } from "../../company-mandate-assignments/hooks/use-company-mandate-assignments";
import { ResponsiveEllipsis } from "../../layout/text";
import { TextAreaInput } from "../../layout/form/text-area-input";
import { Mandate } from "../../mandates/domain/mandate";
import { MandateMandateEffect } from "../../mandate-mandate-effects/domain/mandate-mandate-effect";
import { IndustryGroup } from "../../industry-groups/domain/industry-group";
import { CompanyMandateAssignment } from "../../company-mandate-assignments/domain/company-mandate-assignment";
import { MandateEffect } from "../../mandate-effects/domain/mandate-effect";
import { MandateEffectModal } from "../../mandate-effects/components/mandate-effect-modal";
import { ViewportList } from "react-viewport-list";
import React from "react";

const MandateRow = ({
    mandate,
    mandateEffect,
    mandateMandateEffect,
    industryGroup,
    companyMandateAssignment,
    onImpactChange,
    onStatusChange,
    onMandateMandateEffectClick,
}: {
    mandate?: Mandate;
    mandateEffect?: MandateEffect;
    mandateMandateEffect?: MandateMandateEffect;
    industryGroup?: IndustryGroup;
    companyMandateAssignment: CompanyMandateAssignment;
    onImpactChange: (val: string) => void;
    onStatusChange: (val: string) => void;
    onMandateMandateEffectClick: (mandateMandateEffectId: number) => void;
}) => {
    return (
        <Tr key={companyMandateAssignment.id}>
            <Td style={{ verticalAlign: "top" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: 500 }}>
                        <Link to={`/mandates/${mandate?.id}`}>{mandate?.name}</Link>
                    </div>
                    <div style={{ color: "gray", fontSize: 14 }}>
                        Date: {mandate?.date && format(mandate.date, "yyyy-MM-dd")}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ color: "gray", fontSize: 14 }}>
                            <div>State: {mandate?.state}</div>
                            <div>County: {mandate?.county}</div>
                            <div>City: {mandate?.city}</div>
                        </div>
                    </div>
                </div>
            </Td>
            <Td style={{ verticalAlign: "top" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: 500 }}>
                        {startCase(mandateEffect?.effectName || "")} {industryGroup ? `(${industryGroup.name})` : null}
                    </div>
                    <div
                        style={{
                            fontSize: 13,
                            color: "gray",
                            ...(mandateMandateEffect?.id ? { cursor: "pointer" } : {}),
                        }}
                        onClick={
                            mandateMandateEffect?.id
                                ? () => onMandateMandateEffectClick(mandateMandateEffect.id)
                                : undefined
                        }
                    >
                        <ResponsiveEllipsis
                            text={mandateMandateEffect?.description || ""}
                            maxLine="4"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                        />
                    </div>
                </div>
            </Td>
            <Td>
                <TextAreaInput
                    defaultValue={companyMandateAssignment.impact || ""}
                    onBlur={(e, value) => {
                        onImpactChange(value);
                    }}
                />
            </Td>
            <Td>
                <Select
                    options={[
                        { label: "Suggested", value: "suggested" },
                        { label: "Accepted", value: "accepted" },
                        { label: "Rejected", value: "rejected" },
                    ]}
                    value={companyMandateAssignment.status || null}
                    onChange={(value: any) => {
                        onStatusChange(value);
                    }}
                />
            </Td>
        </Tr>
    );
};

export const Mandates = ({ companyId, owner }: { companyId: number; owner: User }) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [showAutoAssignModal, setShowAutoAssignModal] = useState(false);
    const [showAddMandateEffectModal, setShowAddMandateEffectModal] = useState(false);
    const [mandateMandateEffectId, setMandateEffectId] = useState<number | undefined>();
    const ref = useRef<HTMLDivElement | null>(null);

    const companyMandateAssignmentsQuery = useCompanyMandateAssignments({
        authToken,
        filters: { where: { companyId } },
    });
    const companyMandateAssignments = useMemo(
        () => companyMandateAssignmentsQuery.data || [],
        [companyMandateAssignmentsQuery.data],
    );

    const mandateIds = companyMandateAssignments.map((assignment) => assignment.mandateId);
    const mandatesQuery = useMandates(
        { authToken, filters: { where: { id: { inq: mandateIds } } } },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandates = useMemo(() => mandatesQuery.data || [], [mandatesQuery.data]);

    const mandateEffectIds = companyMandateAssignments.map((a) => a.mandateEffectId);
    const mandateEffectsQuery = useMandateEffects(
        {
            authToken,
            filters: { where: { id: { inq: mandateEffectIds } } },
        },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandateEffects = useMemo(() => mandateEffectsQuery.data || [], [mandateEffectsQuery.data]);

    const mandateMandateEffectsQuery = useMandateMandateEffects(
        {
            authToken,
            filters: { where: { mandateId: { inq: mandateIds, mandateEffectId: { inq: mandateEffectIds } } } },
        },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandateMandateEffects = useMemo(
        () => mandateMandateEffectsQuery.data || [],
        [mandateMandateEffectsQuery.data],
    );

    const i = companyMandateAssignments.filter((a) => !!a.industryGroupId);

    const industryGroupIds = i.map((assignment) => assignment.industryGroupId);
    const industryGroupsQuery = useIndustryGroups(
        {
            authToken,
            filters: { where: { id: { inq: industryGroupIds } } },
        },
        {
            enabled: companyMandateAssignmentsQuery.isSuccess && i.length > 0,
        },
    );
    const industryGroups = useMemo(() => industryGroupsQuery.data || [], [industryGroupsQuery.data]);

    const rows = useMemo(
        () =>
            orderBy(
                companyMandateAssignments.map((cma) => {
                    return {
                        companyMandateAssignment: cma,
                        mandate: mandates.find((m) => m.id === cma.mandateId),
                        mandateEffect: mandateEffects.find((me) => me.id === cma.mandateEffectId),
                        mandateMandateEffect: mandateMandateEffects.find(
                            (me) => me.mandateId === cma.mandateId && me.mandateEffectId === cma.mandateEffectId,
                        ),
                        industryGroup: industryGroups.find((ig) => ig.id === cma.industryGroupId),
                    };
                }),
                (row) => row.mandate?.name,
                "desc",
            ),
        [companyMandateAssignments, industryGroups, mandateEffects, mandateMandateEffects, mandates],
    );

    const suggestedRows = useMemo(
        () => rows.filter((row) => row.companyMandateAssignment.status === "suggested"),
        [rows],
    );
    const acceptedRows = useMemo(
        () => rows.filter((row) => row.companyMandateAssignment.status === "accepted"),
        [rows],
    );
    const rejectedRows = useMemo(
        () => rows.filter((row) => row.companyMandateAssignment.status === "rejected"),
        [rows],
    );

    const allRows = useMemo(
        () => [
            { header: `Suggested Mandate Effects (${suggestedRows.length})` },
            ...suggestedRows,
            { header: `Accepted Mandate Effects (${acceptedRows.length})` },
            ...acceptedRows,
            { header: `Rejected Mandate Effects (${rejectedRows.length})` },
            ...rejectedRows,
        ],
        [acceptedRows, rejectedRows, suggestedRows],
    );

    const updateCompanyMandateAssignmentMutation = useMutation({
        mutationKey: ["updateCompanyMandateAssignment", { authToken }],
        mutationFn: async ({ id, data }: { id: number; data: UpdateCompanyMandateAssignment }) => {
            const updatedMandateAssignment = await updateCompanyMandateAssignment({ authToken, id, data });
            return updatedMandateAssignment;
        },
        onSettled: () => companyMandateAssignmentsQuery.refetch(),
    });

    const mandateMandateEffectToView = useMemo(
        () => mandateMandateEffects.find((mme) => mme.id === mandateMandateEffectId),
        [mandateMandateEffects, mandateMandateEffectId],
    );

    return (
        <>
            {showAutoAssignModal && (
                <AutoAssignMandatesModal
                    companyId={companyId}
                    onClose={() => setShowAutoAssignModal(false)}
                    onAssigned={() => {
                        companyMandateAssignmentsQuery.refetch();
                        setShowAutoAssignModal(false);
                    }}
                />
            )}
            {showAddMandateEffectModal && (
                <AddMandateAssignmentModal
                    companyId={companyId}
                    onClose={() => setShowAddMandateEffectModal(false)}
                    onCreated={() => {
                        setShowAddMandateEffectModal(false);
                        companyMandateAssignmentsQuery.refetch();
                    }}
                />
            )}
            {mandateMandateEffectToView && (
                <MandateEffectModal
                    mandateMandateEffect={mandateMandateEffectToView}
                    onClose={() => setMandateEffectId(undefined)}
                />
            )}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 12 }}>
                <div style={{ fontSize: 24, fontWeight: 500 }}>Mandates Assigned To This Company</div>
                <div>
                    <ButtonNeoGen onClick={() => setShowAddMandateEffectModal(true)}>Add Mandate Effect</ButtonNeoGen>
                    <ButtonNeoGen
                        onClick={() => {
                            setShowAutoAssignModal(true);
                        }}
                    >
                        Auto-Assign Mandates
                    </ButtonNeoGen>
                </div>
            </div>
            <div ref={ref} style={{ height: "calc(100vh - 210px)", overflow: "auto" }}>
                <Table>
                    <THead>
                        <Tr>
                            <Th style={{ width: 190 }}>Mandate</Th>
                            <Th>Effect</Th>
                            <Th>Impact</Th>
                            <Th style={{ width: 190 }}>Status</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {rows.length === 0 && (
                            <Tr>
                                <Td colSpan={5} style={{ textAlign: "center" }}>
                                    No Mandate Effects
                                </Td>
                            </Tr>
                        )}
                        <ViewportList
                            viewportRef={ref}
                            items={allRows}
                            renderSpacer={({ ref, style }) => <Tr ref={ref} style={style} />}
                            initialPrerender={20}
                        >
                            {(row: any) => {
                                return (
                                    <React.Fragment key={row.header || row.companyMandateAssignment.id}>
                                        {row.header ? (
                                            <Tr>
                                                <Td colSpan={4}>
                                                    <b>{row.header}</b>
                                                </Td>
                                            </Tr>
                                        ) : (
                                            <MandateRow
                                                companyMandateAssignment={row.companyMandateAssignment}
                                                industryGroup={row.industryGroup}
                                                mandate={row.mandate}
                                                mandateEffect={row.mandateEffect}
                                                mandateMandateEffect={row.mandateMandateEffect}
                                                onMandateMandateEffectClick={(id) => setMandateEffectId(id)}
                                                onImpactChange={(val) => {
                                                    updateCompanyMandateAssignmentMutation.mutate({
                                                        id: row.companyMandateAssignment.id,
                                                        data: { impact: val },
                                                    });
                                                }}
                                                onStatusChange={(val: any) => {
                                                    if (val) {
                                                        updateCompanyMandateAssignmentMutation.mutate({
                                                            id: row.companyMandateAssignment.id,
                                                            data: { status: val },
                                                        });
                                                    }
                                                }}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            }}
                        </ViewportList>
                    </TBody>
                </Table>
            </div>
        </>
    );
};
