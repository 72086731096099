import { z } from "zod";

export const companyMandateAssignmentSchema = z.object({
    id: z.number(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    companyId: z.number(),
    mandateId: z.number(),
    mandateEffectId: z.number(),
    industryGroupId: z.number().nullish(),
    suggestedByAi: z.boolean().nullish(),
    status: z.enum(["suggested", "accepted", "rejected"]).nullish(),
    impact: z.string().nullish(),
});

export type CompanyMandateAssignment = z.infer<typeof companyMandateAssignmentSchema>;
